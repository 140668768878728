import React,{useState} from "react"
import { Container, Modal } from "react-bootstrap"
import "./TeamDetailsBanner.scss"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import DefaultForm from "../forms/default-form-module"
import FormFields from "../../../static/forms/team.json"
import noImage from "../../images/no-image.png"
import { graphql, useStaticQuery } from "gatsby"
import ScrollAnimation from "react-animate-on-scroll"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const TeamDetailsBanner = ({ teamInfo }) => {
  const data=useStaticQuery(graphql`
      query{
        allStrapiSiteConfig {
          edges {
            node {
              whatsapp_message
              
            }
          }
        }
      }
  `)
  const imagename = "team.tile_image.full_image"

  const message=data?.allStrapiSiteConfig?.edges?.length>0&&data?.allStrapiSiteConfig?.edges[0]?.node?.whatsapp_message

  const [show, setShow] = useState(false)

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  let processedImages
  processedImages =
    teamInfo?.imagetransforms?.image_Transforms || JSON.stringify({})

  const { isLargeScreen } = useDeviceMedia()

  const getVcard = () => {
    let formvalues = "BEGIN:VCARD\n"
    formvalues += "VERSION:3.0\n"
    formvalues += "FN;CHARSET=UTF-8:" + teamInfo?.name + "\n"
    formvalues += "N;CHARSET=UTF-8:;" + teamInfo?.name + ";;;\n" //first name, middle name and last name ;;;
    formvalues +=
      "EMAIL;CHARSET=UTF-8;type=WORK,INTERNET:" + teamInfo?.email + "\n"
    formvalues += "PHOTO;TYPE=JPEG:" + teamInfo?.image?.url + "\n"
    formvalues += "TEL;TYPE=CELL:" + teamInfo?.phone + "\n"
    formvalues += "TITLE;CHARSET=UTF-8:" + teamInfo?.designation + "\n"
    formvalues += "ROLE;CHARSET=UTF-8:" + teamInfo?.designation + "\n"
    formvalues += "ORG;CHARSET=UTF-8:haus & haus\n" // org
    formvalues += "REV:2023-07-21T19:14:04.961Z\n" // set current date time
    formvalues += "END:VCARD"

    const linkElement = document.createElement("a")
    linkElement.setAttribute("href", `data:,${formvalues}`)
    linkElement.setAttribute("download", `${teamInfo?.name}.vcf`)
    linkElement.style.display = "none"
    document.body.appendChild(linkElement)
    linkElement.click()
    document.body.removeChild(linkElement)
  }


  return (
    <div className="team-details-banner">
      <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
      <Container> 
        <div className="team-details-banner-content">
          <div className="image-section">
            {teamInfo?.tile_image?.url?
            <ImageModule
              ImageSrc={teamInfo?.tile_image}
              altText={`${teamInfo?.name}`}
              imagetransforms={processedImages}
              renderer="pic-src"
              imagename={imagename}
              strapi_id={teamInfo?.strapi_id}
              classNames="img-fluid"
            />
            // <img src={teamInfo?.tile_image?.url} alt={`${teamInfo?.name}`} classNames="img-fluid" />
            :
            <img src={noImage} alt="No Image"/>
          }
          </div>
          <div className="details-content_section">
            <h1>{teamInfo?.name}</h1>
            <p className="designation">{teamInfo?.designation}</p>
            {!isLargeScreen && (
              <div className="cta-holder">
                {teamInfo?.whatsapp && (
                  <a
                  target="_blank"
                    className="whatsapp-link"
                    href={`https://wa.me/${teamInfo?.whatsapp?.replace(
                      /[^A-Z0-9]/gi,
                      ""
                    )}?text=${message}`}
                  >
                    <button className="button button-filled-green">
                      {" "}
                      <i className="icon black-whatsapp" />
                      <span>whatsapp</span>
                    </button>
                  </a>
                )}

                {teamInfo?.email&&
                  <a href="javascript:void(0)"
                  onClick={handleModal}
                  >
                    <button className="button button-outline-white">
                      {" "}
                      <i className="icon black-mail" /> <span>Email</span>
                    </button>
                  </a>
                } 
              
                {teamInfo?.phone && (
                  <a href={`tel:${teamInfo?.phone}`}>
                    <button className="button button-outline-white">
                      {" "}
                      <i className="icon black-mobile" /> <span>Call</span>
                    </button>
                  </a>
                )}
              </div>
            )}

        

            <div className="download-vcard" onClick={() => getVcard()}>
              <div className="downloads">
                <i className="icon vcard-icon" />
                <span>Download vCard</span>
              </div>
            </div>
            <p className="description">
              <ContentModule Content={teamInfo?.content?.data?.content} />
            </p>
          </div>
        </div>
      </Container>
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form team-form"
      >
        <Modal.Body>
          <div className="popup-form-wrapper team-form">
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon black-cancel-icon"></i>
            </div>
            <div>
              <DefaultForm
                fields={FormFields} 
                formTitle={"Contact"+ " "+teamInfo?.name}
                sourceUrl={pageurl}
                emailSubUser={`Contact ${teamInfo?.name}`}
                emailSubAdmin={`Contact ${teamInfo?.name}`}
                team_name={teamInfo?.name}
                to_email_id={teamInfo?.email}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>  
      </ScrollAnimation>  
    </div>
  )
}

export default TeamDetailsBanner
