import { graphql } from "gatsby"
import React, { useState } from "react"
import { Container, Modal } from "react-bootstrap"
import FormFields from "../../static/forms/team.json"
import { PageLinks } from "../common/site/page-static-links"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import { formTracking } from "../components/Common/utils"
import SocialShareComponent from "../components/SocialShareComponent/SocialShareComponent"
import TeamBlogs from "../components/TeamBlogs/TeamBlogs"
import TeamDetailsBanner from "../components/TeamDetailsBanner/TeamDetailsBanner"
import TeamProperty from "../components/TeamProperty/TeamProperty"
import DefaultForm from "../components/forms/default-form-module"
import Layout from "../components/layout"
import SEO from "../components/seo"
import useDeviceMedia from "../hooks/useDeviceMedia"
import TeamReview from "../components/TeamReview/TeamReview"


const TeamDetails = ({ data }) => {
  const teamInfo = data.strapiTeam
  const teamMemberReview= data?.allStrapiReview?.edges;

  const [shareIcons, setShareIcons] = useState(false)

  const [show, setShow] = useState(false)

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const PageData =
    data?.allStrapiPage?.edges?.length > 0
      ? data?.allStrapiPage?.edges[0]?.node
      : ""

  let popUpData = ""

  if (PageData?.add_page_modules?.length > 0) {
    const results = PageData?.add_page_modules?.filter(
      item => item.__typename === "STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT"
    )
    if (results?.length > 0) {
      popUpData = results[0]
    }
  }

  const { isLargeScreen, isMobile } = useDeviceMedia()

  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
    formTracking({
      event_tracking: "share-property",
      form_name: "Share Property ",
    })
  }

  const message =
    data?.allStrapiSiteConfig?.edges?.length > 0 &&
    data?.allStrapiSiteConfig?.edges[0]?.node?.whatsapp_message

  
    const teamReviewData= data?.allStrapiSiteConfig?.edges?.length > 0 &&
    data?.allStrapiSiteConfig?.edges[0]?.node

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  const revieweeData={
    revieweeName:teamInfo.name,
    revieweeEmail:teamInfo.email
  }

  return (
    <>
      <Layout popUpData={popUpData} nonpropertyDetails dark={true}>
        <div className="layout-padding-top"></div>
        <Container>
          <div className="breadcum-share-wrapper">
            <BreadcrumbModule
              type="share-button"
              mainparentname={`About`}
              mainparent={PageLinks?.about}
              parentname={`Meet the team`}
              parent={PageLinks?.team}
              pagename={teamInfo?.name}
            />

            <div
              className="share-news-details"
              onClick={() => openShareicons()}
            >
              <i className="icon grey-share" />
              <span>Share</span>
              {shareIcons && (
                <SocialShareComponent
                  openShareicons={openShareicons}
                  shareurl={pageurl}
                />
              )}
            </div>
          </div>
        </Container>

        <TeamDetailsBanner teamInfo={teamInfo} />
        <TeamProperty teamInfo={teamInfo} />
        <TeamBlogs teamInfo={teamInfo} />
        {teamMemberReview?.length>0&&
          <TeamReview 
          revieweeData={revieweeData}
          reviewData={teamMemberReview} 
          staticData={teamReviewData}/>
        }
      </Layout>

      {isLargeScreen && (
        <div className="team-cta-holder-mobile">
          <div className="cta-holder team-mobile">
            {teamInfo?.whatsapp && (
              <a
                target="_blank"
                className="whatsapp-link"
                href={`https://wa.me/${teamInfo?.whatsapp?.replace(
                  /[^A-Z0-9]/gi,
                  ""
                )}?text=${message}`}
              >
                <button className="button button-filled-green">
                  {" "}
                  <i className="icon black-whatsapp" />
                  {!isMobile && <span>whatsapp</span>}
                </button>
              </a>
            )}

            {teamInfo?.email && (
              <a
                onClick={handleModal}
                className="email-link"
                href="javascript:void(0)"
              >
                <button className="button button-outline-white">
                  {" "}
                  <i className="icon black-mail" /> <span>Email</span>
                </button>
              </a>
            )}
            {
              <Modal
                show={show}
                // onHide={handleClose}
                backdrop="static"
                centered
                dialogClassName="modal-popup-form team-form"
              >
                <Modal.Body>
                  <div className="popup-form-wrapper team-form">
                    <div className="close-modal" onClick={() => handleClose()}>
                      <i className="icon black-cancel-icon"></i>
                    </div>
                    <div>
                      <DefaultForm
                        fields={FormFields}
                        formTitle={"Contact" + " " + teamInfo?.name}
                        sourceUrl={pageurl}
                        emailSubUser={`Contact ${teamInfo?.name}`}
                        emailSubAdmin={`Contact ${teamInfo?.name}`}
                        team_name={teamInfo?.name}
                        to_email_id={teamInfo?.email}
                      />
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            }
            {teamInfo?.phone && (
              <a className="phone-num" href={`tel:${teamInfo?.phone}`}>
                <button className="button button-outline-white">
                  {" "}
                  <i className="icon black-mobile" /> <span>Call</span>
                </button>
              </a>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export const Head = ({ data }) => {
  const teamInfo = data.strapiTeam

  let myimgtransforms = ""
  if (teamInfo?.imagetransforms?.tile_image_Transforms) {
    myimgtransforms = JSON.parse(
      teamInfo?.imagetransforms?.tile_image_Transforms
    )
  }

  let imageUrl=""

  const findImage = teamInfo?.tile_image?.url?.substring(teamInfo?.tile_image?.url?.indexOf("i.prod"));
  const ProcessedResult=findImage&&myimgtransforms[findImage]?myimgtransforms[findImage]["webp"]:""
  imageUrl=ProcessedResult?ProcessedResult["744ct520"]:teamInfo?.tile_image?.url

  const metaTitle = teamInfo?.name + " | " + teamInfo?.designation + " | "
  const metaDesc = `Get to know about ${teamInfo?.name} here. Contact one of our real estate agents for assistance in finding the right Properties for you.`
  return <SEO title={metaTitle} description={metaDesc} imageUrl={imageUrl}></SEO>
}
export default TeamDetails

export const query = graphql`
  query ($page_id: Int, $email:String) {
    strapiTeam(strapi_id: { eq: $page_id }) {
      content {
        data {
          content
        }
      }
      designation
      email
      image {
        url
      }
      tile_image {
        url
      }
      imagetransforms {
        image_Transforms
        tile_image_Transforms
      }
      name
      phone
      slug
      whatsapp
      strapi_id
      negotiator_id
    }
    allStrapiSiteConfig {
      edges {
        node {
          whatsapp_message
          team_review_heading
          team_review_desc {
            data {
              team_review_desc
            }
          }
        }
      }
    }

    allStrapiReview(
      filter: {email: {eq: $email}, publish: { eq: true }}
      sort: { fields: createdAt, order: DESC }
      ) {
      edges {
        node {
          title
          email
          name
          reviewer_name
          rating
          createdAt
          review_date
          content {
            data {
              content
            }
          }
          department
        }
      }
    }

    allStrapiPage(filter: { layout: { eq: "people_landing_page" } }) {
      edges {
        node {
          add_page_modules {
            ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
              __typename
              ...GlobalPopUpFragment
            }
          }
        }
      }
    }
  }
`
