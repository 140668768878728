import React from 'react'
import CustomSlider from "../CustomSlider/CustomSlider"
import Rating from "../Rating/Rating"
import dateFormat from "dateformat"
import { Container } from "react-bootstrap"
import "./TeamReview.scss"
import { reviewUrl } from "../../lib/urls"
import { Link } from "gatsby"

const {
    ContentModule,
  } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const TeamReview = ({reviewData,staticData,revieweeData}) => {
    const sortdData=reviewData?.sort((a,b)=>{
        if (a.node?.review_date === null && b.node?.review_date === null) {
          return 0;
        } else if (a.node?.review_date === null) {
          return 1; // Null values at the end
        } else if (b.node?.review_date === null) {
          return -1; // Null values at the beginning
        }
      
        // Compare dates
        return b.node?.review_date - a.node?.review_date
      }
      )
    const data=sortdData?.slice(0,3)
  return (
    <div className="reviewee-component-wrapper">
     <Container>
        <div className="team-review-module">
            <div className="heading-review-team">
                <h2>{staticData?.team_review_heading}</h2>
                <p className="description-team-review">
                <ContentModule
                    Content={staticData?.team_review_desc?.data?.team_review_desc}
                /> 
               <Link state={{revieweeData:revieweeData}}  to={reviewUrl} className="more-review-wrapper"><span className="more-reviews">More Reviews</span> <i className="icon arrow-right-icon"/></Link>
                </p>
            </div>
            <CustomSlider
                className="reviewee-slider"
                slidecount={3}
                showTwo
            >
                {data?.map(({ node, i }) => {
                    return(
                        <div class="item">
                            <h5>{node?.reviewer_name}</h5> 
                            {node?.review_date&&
                                <p className="review-createdat">{dateFormat(node?.review_date,"d mmmm yyyy")}</p>
                            }
                             <Rating starRating={node?.rating} />
                             <p className="description-review">
                             <ContentModule
                                Content={
                                    node?.content?.data?.content &&
                                    node?.content?.data?.content.length > 220
                                    ? node?.content?.data?.content.substring(0, 220) + "... "
                                    : node?.content?.data?.content
                                }
                            />
                            </p>

                        </div>
                    )

                })}
            </CustomSlider>
        </div>


     </Container>
    </div>
  )
}

export default TeamReview